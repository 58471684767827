<template>
  <div class="container">
    <h4 class="text-center my-4">Академическая задолженность</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else class="my-4">

      <div class="my-4">
        <p class="text-danger">Перед написанием заявления проверьте номер телефона на странице
          <router-link to="/profile" target="_blank">Мой профиль</router-link>
        </p>
      </div>

      <div class="my-4">
        <Button type="button" icon="pi pi-plus" label="Добавить заявление" class="p-button-rounded"
                @click="openAddAcademicDebtStatementDialog"/>
      </div>

      <div v-if="academicDebt_form.summerCourseRequest.length">
        <DataTable :value="academicDebt_form.summerCourseRequest" :paginator="true" :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="Показано {last} из {totalRecords}"
                   stripedRows responsiveLayout="scroll">

          <Column field="reason_text" header="Причина"></Column>
          <Column header="Файл">
            <template #body="{data}">

              <div v-if="data.file == null">
                Квитанции нет
              </div>
              <div v-else>
                <a :href="'https://back.uib.kz/uploads/' + data.file" target="_blank">
                  Квитанция
                </a>
              </div>

            </template>
          </Column>
          <Column header="Дата">
            <template #body="{data}">
              {{ convertTimestampToDate(data.created_at) }}
            </template>
          </Column>
          <Column header="Дисциплины">
            <template #body="{data}">

              <ul class="mb-1">
                <li v-for="(disciplines, disciplineIndex) in data.disciplines" :key="disciplineIndex">
                  {{ disciplines.name}} - Количество кредитов: {{ disciplines.credit}}
                </li>
              </ul>

            </template>
          </Column>
          <Column header="Статус">
            <template #body="{data}">
              <div v-if="data.status == 0">
                Отправлено
              </div>
              <div v-if="data.status == 1">
                Потверждено
              </div>
              <div v-if="data.status == 2">
                Отказано
              </div>
            </template>
          </Column>

        </DataTable>
      </div>


      <Dialog v-model:visible="addAcademicDebtStatementDisplay" :style="{width: '100%',maxWidth: '1000px'}"
              :closable="false">
        <template #header>
          <h5>Добавить заявление</h5>
        </template>

        <div class="col-md-12 mt-4">
          <div class="my-5">
            <div>
              <p class="text-center">Заявление</p>
              <p>
                Прошу Вас разрешить освоение следующих дисциплин в летнем семестре 2020 - 2021 учебного года по причине:
              </p>
            </div>
            <div class="row mt-4">
              <label class="col-md-6 col-form-label">Причина</label>
              <div class="col-md-6">

                <select class="form-control form-select" @input="changeReason($event)">
                  <option
                    v-for="(item, index) in [{id: 0, name: 'Выберите причину'}, ...academicDebt_form.summerCourseReasons]"
                    :value="JSON.stringify(item)"
                    :key="index">{{ item.name }}
                  </option>
                </select>

                <div class="mt-4" v-if="reason_id == 5">
                  <input id="reason" type="text" class="form-control"
                         placeholder="Напишите свою причину" @input="changeReasonText($event)">
                </div>


              </div>
            </div>


          </div>


          <div class="disciplines_table">
            <DataTable :value="academicDebt_form.academicDebts"
                       stripedRows responsiveLayout="scroll">

              <Column field="discipline_name" header="Название дисциплины"></Column>
              <Column field="credit" header="Кредит"></Column>
              <Column header="Сумма к оплате">
                <template #body="{data}">
                  {{ Math.round(data.price_sum) }}
                </template>
              </Column>
              <Column header="Выбор">
                <template #body="{data}">
                  <input class="form-check-input" type="checkbox"
                         :value="data"
                         v-model="checked_disciplines">
                </template>
              </Column>


            </DataTable>
          </div>

          <div class="my-5" v-if="checked_disciplines.length">

            <div class="flex flex-column md:flex-row md:justiify-content-between">
              Общая сумма: <b>{{ totalPriceSum }}</b>
            </div>

            <div class="row mt-4">
              <label class="col-md-3 col-form-label">Прикрепите чек</label>
              <div class="col-md-9">
                <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control"
                       @change="handleFileUpload($event)">
              </div>
            </div>
          </div>


        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeAddAcademicDebtStatementDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="save"
                  v-if="checked_disciplines.length > 0 && academicDebt_form.statement.file_url && reason_id != 0"/>
        </template>
      </Dialog>

    </div>

  </div>


</template>

<script>
  import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
  import {mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "AcademicDebtStudent",
    data() {
      return {
        loading: true,
        addAcademicDebtStatementDisplay: false,
        checked_disciplines: [],
        reason_id: 0,
        reason_text: ''
      }
    },
    computed: {
      ...mapState('academicDebt', ['academicDebt_form']),
      checkedDisciplines() {
        return this.checked_disciplines.map(i => ({
          student_ratings_id: i.student_ratings_id,
          old_moodle_student_rating_id: i.old_moodle_student_rating_id,
          student_practice_ratings_id: i.student_practice_ratings_id,
          difference_ratings_id: i.difference_ratings_id,
          price_sum: i.price_sum,
          is_oldmoodle: i.is_oldmoodle,
          is_practice: i.is_practice
        }))
      },
      totalPriceSum() {
        return this.checkedDisciplines.reduce((sum, i) => Math.round(+i.price_sum) + sum, 0)
      },
    },

    methods: {
      ...mapActions('academicDebt', ['GET_ACADEMIC_DEBTS', 'GET_SUMMER_COURSE_REASONS', 'POST_STUDENT_SUMMER_COURSE_REQUEST', 'GET_SUMMER_COURSE_REQUEST']),
      ...mapMutations('academicDebt', ['SET_STUDENT_SUMMER_COURSE_DISCIPLINES', 'SET_STUDENT_SUMMER_COURSE_REASON', 'SET_STUDENT_SUMMER_COURSE_FILE', 'SET_STUDENT_SUMMER_COURSE_REASON_TEXT']),
      convertTimestampToDate,
      openAddAcademicDebtStatementDialog() {
        this.addAcademicDebtStatementDisplay = true
      },
      closeAddAcademicDebtStatementDialog() {
        this.checked_disciplines = []
        this.addAcademicDebtStatementDisplay = false
      },
      changeReason(e) {
        let reason = JSON.parse(e.target.value)
        this.reason_id = reason.id
        this.reason_text = reason.name
        this.SET_STUDENT_SUMMER_COURSE_REASON({reason_id: this.reason_id, reason_text: this.reason_text})
      },
      changeReasonText(e) {
        this.reason_text = e.target.value
        this.SET_STUDENT_SUMMER_COURSE_REASON_TEXT(this.reason_text)
      },
      changeDisciplines(e) {
        const value = e.target.value
        this.SET_STUDENT_SUMMER_COURSE_DISCIPLINES(value)
      },
      handleFileUpload(e) {
        let file = e.target.files[0];
        this.SET_STUDENT_SUMMER_COURSE_FILE(file)
      },
      async save() {
        this.addAcademicDebtStatementDisplay = false

        this.postDisciplines = this.checked_disciplines.map(i => ({
          student_ratings_id: i.student_ratings_id,
          is_oldmoodle: i.is_oldmoodle,
          old_moodle_student_rating_id: i.old_moodle_student_rating_id,
          student_practice_ratings_id: i.student_practice_ratings_id,
          difference_rating_id: i.difference_ratings_id,
          is_practice: i.is_practice
        }))

        // console.log('postDisciplines', this.postDisciplines)


        const res = await this.POST_STUDENT_SUMMER_COURSE_REQUEST(this.postDisciplines)
        if (res) {
          this.$message({text: 'Заявка успешно подана'})
          await this.GET_SUMMER_COURSE_REQUEST()
          await this.GET_ACADEMIC_DEBTS()
        }

      }
    },

    async mounted() {
      await this.GET_SUMMER_COURSE_REQUEST()
      await this.GET_ACADEMIC_DEBTS()
      await this.GET_SUMMER_COURSE_REASONS()

      this.loading = false
    }
  }
</script>

<style scoped>

</style>